/* Welcome */

a {
  text-decoration: none;
}
.welcome__content {
  margin: 0 auto;
  padding: 32px 16px;
  max-width: 1440px;
}

.welcome__banner-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 5px;
}

.welcome__desc {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.welcome__desc h1 {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.31;
  color: #000;
}

.welcome__desc h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
  color: #83a4d7;
}

button.meeting-date {
  width: max-content;
  margin: 8px 0;
  padding: 7px 25px;
  border-radius: 5px;
  background-color: #7ba4dc;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.split-line {
  width: 70%;
  height: 1px;
  margin: 4px 0;
  border-radius: 1px;
  background-color: #83a4d7;
}

.welcome__banner {
  width: 50%;
  height: 70px;
  background-color: #fff;
  background-image: url("../../img/banner.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
}

.banner-btn {
  width: 100%;
  font-size: 12px;
  margin-bottom: 5px;
  padding: 8px 25px;
}

.welcome__image {
  margin-bottom: 24px;
  text-align: center;
}

.welcome__image .image {
  max-width: 100%;
}

.welcome__image .image--desktop {
  display: none;
}

.welcome__block {
  /* text-align: center; */
}

.welcome__subtitle {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.57;
  color: #2083ce;
  text-transform: uppercase;
}

.welcome__title {
  margin: 0 0 16px 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.29;
  color: #000;
}

.welcome__text {
  margin-bottom: 24px;
  text-align: left;
}

.welcome__text p {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #2c2c2c;
}

/* Webianrs */
.webinars__content {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1440px;
}

.webinars--block {
  padding: 32px 0;
}

.webinars--block .block__title {
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #2083ce;
  text-transform: uppercase;
}

.webinars__list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 8px -16px;
}

.webinars__list .list__item {
  margin: 0 16px 20px 16px;
}

.webinars__list .list__item:last-child {
  margin-bottom: 0;
}

.webinars__list .card {
  max-width: 350px;
  padding: 24px 16px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 #efefef;
}

.webinars__list .card__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.webinars__list .card:hover .webinar__date,
.webinars__list .card:hover .webinar__time,
.webinars__list .card:hover .webinar__title {
  color: #2083ce;
}

.webinar__date.presentations {
  margin-bottom: 10px;
}

.webinar__date.presentations .icons.svg.i16x16 {
  margin-right: 10px;
}

.webinars__list .card:hover .webinar__date .icons,
.webinars__list .card:hover .webinar__time .icons,
.webinars__list .card:hover .webinar__title .icons {
  fill: #2083ce;
}

.webinars__list .card:hover .webinar__link .btns-more {
  opacity: 1;
}

.webinars__list .webinar__date,
.webinars__list .webinar__time {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #a1bcd0;
}

.webinars__list .webinar__date .icons,
.webinars__list .webinar__time .icons {
  fill: #a1bcd0;
}

.webinars__list .webinar__title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  color: #000;
}

.webinars__list .webinar__description {
  margin-bottom: 10px;
}

.webinars__list .webinar__description p {
  font-size: 14px;
  line-height: 1.57;
  color: #5c5c5c;
}

.webinars__list .webinar__link .btns-more {
  font-size: 16px;
  color: #2083ce;
  opacity: 0.5;
  padding-right: 30px;
  background: url("../../img/icons/i-arrow_right.svg") no-repeat 100% 4px;
}

.webinars__empty .empty__text {
  margin-bottom: 42px;
}

.webinars__empty .empty__text p {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: #a3bdd1;
}

.webinars__empty .empty__icons .icon {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 96px;
  background: url("../../img/webinars_empty.svg") no-repeat 50%;
  background-size: cover;
}

.webinars--page {
  padding-top: 42px;
}

.webinars--page .page__title {
  text-transform: uppercase;
}

.webinars--page .search {
  margin: 0 auto 28px;
  max-width: 620px;
}

.webinars--article {
  margin: 0 auto;
  padding: 42px 0 100px 0;
  max-width: 980px;
}

.webinars--article .page__title {
  margin-bottom: 16px;
}

.webinars--article .block__title {
  margin-bottom: 10px;
  font-size: 16px;
  color: #3190d7;
}

.webinars--article .webinar__datetime {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.webinars--article .webinar__date,
.webinars--article .webinar__time {
  margin: 0 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #2083ce;
}

.webinars--article .webinar__date .icons,
.webinars--article .webinar__time .icons {
  fill: #2083ce;
}

.webinars--article .webinar__content {
  margin-bottom: 24px;
  padding: 24px 0 32px 0;
  border-top: 1px solid rgba(161, 188, 208, 0.2);
  border-bottom: 1px solid rgba(161, 188, 208, 0.2);
}

.webinars--article .webinar__description {
  margin-bottom: 32px;
}

.webinars--article .webinar__description p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #313131;
}

.webinars--article .webinar__btns {
  text-align: center;
}

/** Webianrs **/

@media only screen and (min-width: 768px) {
  .welcome__content {
    padding: 100px 16px;
  }

  .banner-btn {
    width: unset;
    font-size: 16px;
    margin-bottom: 0;
    margin-right: 12px;
  }

  .welcome__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .welcome__banner {
    height: 200px;
  }

  .welcome__image {
    flex: 0 0 50%;
    margin-bottom: 0;
    width: 50%;
    padding-left: 15px;
  }

  .welcome__image .image--mobile {
    display: none;
  }

  .welcome__image .image--desktop {
    display: inline-block;
  }

  .welcome__block {
    flex: 0 0 50%;
    width: 50%;
    padding-right: 15px;
  }

  /* Webianrs */
  .webinars__list .list__item {
    margin-bottom: 32px;
  }

  .webinars__empty .empty__icons .icon {
    width: 343px;
    height: 164px;
  }

  /** Webianrs **/
}

@media only screen and (min-width: 1024px) {
  /* Welcome */
  .welcome__content {
    padding-right: 50px;
    padding-left: 50px;
  }

  .welcome__subtitle {
    font-size: 22px;
    line-height: 1.45;
  }

  .welcome__title {
    font-size: 42px;
    line-height: 1.23;
  }

  .welcome__text p {
    font-size: 16px;
    line-height: 1.5;
  }

  .welcome__text p a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  /** Welcome **/
  /* Webianrs */
  .webinars__content {
    padding-right: 50px;
    padding-left: 50px;
  }

  .webinars--block {
    padding-bottom: 90px;
  }

  .webinars__list .webinar__title {
    font-size: 24px;
    line-height: 1.33;
  }

  .webinars--page {
    padding-top: 80px;
  }

  .webinars--page .page__title {
    margin-bottom: 42px;
  }

  .webinars--page .search {
    margin-bottom: 42px;
  }

  .webinars--article .page__title {
    margin-bottom: 14px;
    font-size: 24px;
  }

  .webinars--article .webinar__datetime {
    margin-bottom: 32px;
  }

  .webinars--article .webinar__content {
    margin-bottom: 32px;
    padding: 24px 0 14px 0;
  }

  .webinars--article .webinar__description p {
    font-size: 16px;
  }

  /** Webianrs **/
}

@media only screen and (min-width: 1440px) {
  .welcome__content {
    padding: 5px 160px;
    padding-bottom: 60px;
  }

  .welcome__title {
    font-size: 52px;
    line-height: 1.23;
  }

  /* Webianrs */
  .webinars__content {
    padding-right: 160px;
    padding-left: 160px;
  }

  /** Webianrs **/
}

@media screen and (max-width: 500px) {
  .welcome__banner-container {
    padding: 20px 10px;
  }

  button.meeting-date {
    padding: 5px 20px;
    font-size: 14px;
  }
}
