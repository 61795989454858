.virtual__content {
  margin: 0 auto;
  padding: 0px 16px;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
}

.virtual__top {
  padding: 64px 0 50px 0;
  text-align: center;
}

.virtual__top .page__title {
  margin-bottom: 16px;
  font-size: 22px;
}

.virtual__top .page__subtitle {
  font-size: 28px;
}

.virtual__top .top__text {
  margin: 0 auto 32px auto;
  max-width: 660px;
}

.virtual__top .top__text p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #2c2c2c;
}

.virtual__top .top__hint {
  padding-top: 16px;
  margin-bottom: 12px;
}

.virtual__top .top__hint p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #2083ce;
}

.virtual__hiw {
  padding: 50px 0 32px 0;
}

.virtual__hiw .section__title {
  text-align: center;
}

.virtual__hiw .hiw__list {
  margin: 0 auto;
  max-width: 500px;
  padding: 24px 16px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0);
}

.virtual__hiw .hiw__list .list__item:after {
  content: "";
  display: block;
  margin: 24px auto;
  width: 150px;
  height: 1px;
  background-color: #eee;
}

.virtual__hiw .hiw__list .list__item:last-child:after {
  display: none;
}

.virtual__hiw .hiw__list .hiw__title {
  margin-bottom: 16px;
}

.virtual__hiw .hiw__list .hiw__title span {
  display: inline-block;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 700;
  color: #2083ce;
  border-radius: 7.6px;
  background-color: #d1e7f9;
}

.virtual__hiw .hiw__list .hiw__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c2c2c;
}

.download-programme {
  margin-top: 24px;
  text-decoration: none;
  text-decoration: underline;
  font-weight: 700;
  color: #2083ce;
}

.gold-sponsors {
  margin: auto;
  margin-top: 24px;
  margin-bottom: 12px;
}

.virtual__bottom img {
  width: 100%;
  margin: auto;
}

.sponsors-container {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-bottom: 85px;
}

.sponsors {
  max-width: 25%;
  max-height: 100px;
  /* height: auto; */
  /* margin: auto; */
}

@media only screen and (min-width: 1024px) {
  .virtual__content {
    padding-right: 50px;
    padding-left: 50px;
  }

  .virtual__top {
    padding-top: 100px;
    padding-bottom: 50px;
    background-image: url("../../img/membeship_top_bg.svg");
    background-position: 50%;
    background-size: cover;
  }

  .virtual__top .page__title {
    margin-bottom: 24px;
    font-size: 22px;
  }

  .virtual__top .page__subtitle {
    font-size: 52px;
  }

  .virtual__top .top__text {
    margin-bottom: 42px;
  }

  .virtual__top .top__text p {
    font-size: 16px;
  }

  .virtual__hiw .hiw__list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    padding: 32px 8px;
    max-width: 1120px;
  }

  .virtual__hiw .hiw__list .list__item {
    position: relative;
    flex: 0 0 33%;
    padding: 0 24px;
  }

  .virtual__hiw .hiw__list .list__item:after {
    position: absolute;
    top: 48px;
    right: 0;
    margin: 0;
    width: 1px;
    height: 200px;
  }

  .virtual__hiw .hiw__list .hiw__title span {
    padding: 6px 16px;
    font-size: 16px;
  }

  .virtual__hiw .hiw__list .hiw__text {
    font-size: 16px;
  }
}
