
.header {
  /*position: fixed;
	top: 0;
	right: 0;
	left: 0;*/
  padding: 14px 0;
  z-index: 4;
}

.header--fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0px 16px;
  max-width: 1440px;
}

.header__content--signinup {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #0092ff;
}

.header__content--signinup .btns {
  margin-left: 8px;
}

.header__logo .logo {
  width: 95px;
  height: 32px;
  background-image: url("../../img/logo_m.png");
  background-image: -webkit-image-set(url("../../img/logo_m.png") 1x, url("../../img/logo_m@2x.png") 2x, url("../../img/logo_m@3x.png") 3x);
  background-image: image-set(url("../../img/logo_m.png") 1x, url("../../img/logo_m@2x.png") 2x, url("../../img/logo_m@3x.png") 3x);
  background-size: cover;
}

.header__block--m {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  padding: 14px 16px;
  width: 100%;
  height: 100%;
  max-width: 320px;
  max-height: 567px;
  background: #fff url("../../img/menu_bg.svg") no-repeat 50%;
  background-size: cover;
  transition: right 0.5s ease;
  z-index: 10;
}

.header__block--m .block__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav {
  margin: 32px 18px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
}

.header__nav .menu__list .list__item {
  margin-bottom: 16px;
  text-align: center;
}

.header__nav .menu__list .list__item:last-child {
  margin-bottom: 0;
}

.header__nav .menu__list .link {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.header__nav .menu__list .link.active,
.header__nav .menu__list .link:hover {
  color: #0092ff;
}

.header__auth .authorized .btns-auth {
  display: none;
}

.header__auth .authorized .menu__list {
  text-align: center;
}

.header__auth .authorized .menu__list .list__item {
  margin-bottom: 16px;
}

.header__auth .authorized .menu__list .list__item:last-child {
  margin-bottom: 0;
}

.header__auth .authorized .menu__list .link {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.header__auth .authorized .menu__list .link .icons {
  margin-right: 8px;
  fill: #000;
}

.header__auth .authorized .menu__list .link.active,
.header__auth .authorized .menu__list .link:hover {
  color: #2083ce;
}

.header__auth .authorized .menu__list .link.active .icons,
.header__auth .authorized .menu__list .link:hover .icons {
  fill: #2083ce;
}

.header__auth .authorized .menu__list .link.logout {
  color: #2083ce;
}

.header__auth .notauthorized .menu__list {
  text-align: center;
}

.header__auth .notauthorized .menu__list .list__item {
  margin-bottom: 8px;
}

.header__auth .notauthorized .menu__list .list__item:last-child {
  margin-bottom: 0;
}

.header.menu-show .header__block--m {
  right: 0;
}

@media only screen and (min-width: 1024px) {
  .header__content {
    padding: 0px 50px;
  }

  .header__content--signinup {
    text-align: right;
  }

  .header__logo {
    order: 1;
    margin-right: 32px;
  }

  .header__block--m {
    flex: 1;
    order: 2;
    position: relative;
    top: unset;
    right: unset;
    width: unset;
    height: unset;
    padding: 0;
    max-width: unset;
    max-height: unset;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__block--m .block__top {
    display: none;
  }

  .header__nav {
    margin: 0;
    padding: 0;
    border: 0 none;
  }

  .header__nav .menu__list .list__item {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 24px;
  }

  .header__auth .authorized .btns-auth {
    display: inline-block;
  }

  .header__auth .authorized .menu {
    position: absolute;
    top: -1000%;
    right: 0;
    padding: 24px 23px 30px 16px;
    border-radius: 10px;
    box-shadow: 0 0 34px 0 #efefef;
    background-color: rgba(255, 255, 255, 0.67);
    transition: top 0.5s ease;
  }

  .header__auth .authorized .menu__list {
    text-align: left;
  }

  .header__auth .notauthorized .menu__list {
    text-align: left;
  }

  .header__auth .notauthorized .menu__list .list__item {
    display: inline-block;
    margin: 0;
    margin-left: 16px;
  }

  .header__btns {
    display: none;
  }

  .header.menu-show .header__auth .authorized .menu {
    top: 100%;
  }
}
