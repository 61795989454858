/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap");

/* Styles */
/* Basic */
* {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body,
html {
  width: 100%;
  color: #000;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #fff;
}

body * {
  font-family: "Quicksand", sans-serif;
}

body.overflow {
  overflow: hidden;
}

body.overlay:before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.63);
  z-index: 4;
}

input,
button {
  border: 0 none;
  background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

button {
  cursor: pointer;
}

.form-error {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 5px;
}

.card-wrapper {
  width: 100%;
  background-color: #f1f3f6;
  padding: 6px;
  border-radius: 6px;
  margin-bottom: 6px;
}

.webinar__time {
  margin-left: 15px;
}

.tabs {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 44px;
}

.tab {
  padding: 11px 42px;
  font-weight: 500;
  /* border-radius: 8px; */
  border: solid 1px #0092ff;
  font-size: 14px;
}

.tab-upcoming {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.tab-recorded {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.tab.active {
  background-color: #2083ce;
  color: #fff;
}

.tab:hover {
  cursor: pointer;
}

/* Menu */
.menu__list {
  list-style: none;
}

.menu__list--horizontal {
  display: flex;
}

.menu__list .link {
  text-decoration: none;
}

/** Menu **/
/* Mixins */
/** Mixins **/
/* Forms */
.form__row {
  position: relative;
  margin-bottom: 16px;
}

.form__row--tac {
  text-align: center;
}

.form__row--submit {
  padding-top: 16px;
}

.form__row.error label,
.form__row.error input,
.form__row.error textarea,
.form__row.error.custom-select .select-selected {
  color: #ff0000;
  border-color: #ff0000;
}

.form__row .i-info {
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -12px;
  z-index: 2;
}

.form__row .i-info:hover + .form__hint {
  display: block;
}

.form__row.disabled {
  opacity: 0.5;
}

.form__label {
  position: relative;
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #000;
}

.form__label--radio {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #2083ce;
}

.form__label--radio:before {
  content: "";
  display: inline-block;
  margin: -4px 10px 0 0;
  width: 24px;
  height: 24px;
  line-height: 1.5;
  background-image: url("./img/icons/i-radio.svg");
  vertical-align: middle;
}

.form__label--checkbox {
  font-size: 14px;
  line-height: 1.71;
  color: #000;
}

.form__label--checkbox.error {
  color: #ff0000;
}

.form__label--checkbox:before {
  content: "";
  display: inline-block;
  margin: -4px 10px 0 0;
  width: 24px;
  height: 24px;
  line-height: 1.5;
  background-image: url("./img/icons/i-checkbox.svg");
  vertical-align: middle;
}

.form__label--checkbox a {
  color: #2083ce;
}

.form__input,
.form__select,
.form__textarea {
  padding: 10px 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: solid 1px rgba(161, 188, 208, 0.22);
  border-radius: 10px;
}

.form__input:disabled,
.form__select:disabled,
.form__textarea:disabled {
  background-color: #e3e5e8;
}

.form__select,
.form__radio,
.form__checkbox {
  display: none;
}

.form__input--search {
  padding: 9px 24px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #fff;
}

.form__radio:checked + label:before {
  background-image: url("./img/icons/i-radio_checked.svg");
}

.form__checkbox:checked + label:before {
  background-image: url("./img/icons/i-checkbox_checked.svg");
}

.form .custom-select {
  position: relative;
}

.form .custom-select .select-selected {
  padding: 10px 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #000;
  border: solid 1px rgba(161, 188, 208, 0.22);
  border-radius: 10px;
  background: #fff url("./img/icons/i-arrow_down.svg") no-repeat
    calc(100% - 16px) 50%;
}

.form .custom-select .select-selected.select-arrow-active {
  background-image: url("./img/icons/i-arrow_up.svg");
}

.form .custom-select .select-items {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 16px;
  background-color: #fff;
  border: solid 1px rgba(161, 188, 208, 0.22);
  border-radius: 10px;
  z-index: 3;
}

.form .custom-select .select-items div {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #000;
}

.form .custom-select .select-items div:last-child {
  margin-bottom: 0;
}

.form .custom-select .select-items.select-hide {
  display: none;
}

.form .link {
  font-size: 16px;
  font-weight: 500;
  color: #0092ff;
  text-decoration: none;
}

.form .steps__list {
  list-style: none;
}

.form .steps__list .list__item {
  display: inline-block;
  margin: 0 8px;
}

.form .steps__list .list__item span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #d2e8f9;
  border-radius: 10px;
}

.form .steps__list .list__item.active span {
  background-color: #2083ce;
}

.form__hint {
  display: none;
  position: absolute;
  right: 0;
  padding: 18px 21px;
  font-size: 16px;
  line-height: 1.38;
  color: #000;
  background-color: #fff;
  border: solid 1px rgba(151, 151, 151, 0.1);
  border-radius: 10px;
  box-shadow: 0 0 34px 0 #efefef;
  z-index: 4;
}

.form__hint:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #fff transparent;
  border-width: 0 15px 10px;
  top: -10px;
  right: 12px;
}

.form__submit--search {
  width: 24px;
  height: 24px;
  background: url("./img/icons/i-search.svg") no-repeat 50%;
}

/** Forms **/
.logo {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.image__link {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

/** Basic **/
/* Content */
.page__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
  background: url("./img/page_bg_m.svg") repeat-y 50% 0%;
  background-size: 100% auto;
}

.page__title {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  color: #000;
}

.page__subtitle {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.57;
  text-align: center;
  color: #2083ce;
  text-transform: uppercase;
}

.content__wrapper {
  flex: 1 0 auto;
  width: 100%;
}

.section__title {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  color: #2083ce;
  text-transform: uppercase;
}

.search__form .form__row {
  position: relative;
  margin-bottom: 0;
}

.search__form .form__submit--search {
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -12px;
}

.pagination {
  padding: 42px 0;
}

.pagination__content {
  margin: 0 auto;
  padding: 0px 16px;
  max-width: 1440px;
}

.pagination__list {
  list-style-type: none;
  text-align: center;
}

.pagination__list .list__item {
  display: inline-block;
  margin: 0 4px;
}

.pagination__list .list__item .link,
.pagination__list .list__item .ellipsis {
  display: block;
  padding-top: 1px;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  text-align: center;
  color: #000;
  text-decoration: none;
  border-radius: 50%;
}

.pagination__list .list__item .link {
  cursor: pointer;
}

.pagination__list .list__item .link--current {
  border: 1px solid #2083ce;
}

/* Sign In & Sign Up */
.signinup {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.signinup__bg {
  flex: 0 0 41%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}

.signinup__bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.signinup__logo {
  position: relative;
  z-index: 2;
}

.signinup__content {
  flex: 0 0 59%;
  padding: 64px 16px;
}

.signinup__form {
  margin: 0 auto;
  max-width: 430px;
}

.signinup__form .form__label--radio {
  display: inline-block;
  width: 48%;
}

.signinup__form .form__label--checkbox {
  padding-left: 40px;
}

.signinup__form .form__label--checkbox:before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
}

.signinup--signin .signinup__bg {
  background-image: url("./img/signin_bg.jpg");
  background-image: -webkit-image-set(
    url("./img/signin_bg.jpg") 1x,
    url("./img/signin_bg@2x.jpg") 2x,
    url("./img/signin_bg@3x.jpg") 3x
  );
  background-image: image-set(
    url("./img/signin_bg.jpg") 1x,
    url("./img/signin_bg@2x.jpg") 2x,
    url("./img/signin_bg@3x.jpg") 3x
  );
  background-size: cover;
}

.signinup--signup .signinup__bg {
  background-image: url("./img/signup_bg.jpg");
  background-image: -webkit-image-set(
    url("./img/signup_bg.jpg") 1x,
    url("./img/signup_bg@2x.jpg") 2x,
    url("./img/signup_bg@3x.jpg") 3x
  );
  background-image: image-set(
    url("./img/signup_bg.jpg") 1x,
    url("./img/signup_bg@2x.jpg") 2x,
    url("./img/signup_bg@3x.jpg") 3x
  );
  background-size: cover;
}

/* Sign In & Sign Up */

/** Welcome **/
/* Membership */
.membership__content {
  margin: 0 auto;
  padding: 0px 16px;
  max-width: 1440px;
}

.membership__top {
  padding: 64px 0 50px 0;
  text-align: center;
}

.membership__top .page__title {
  margin-bottom: 16px;
  font-size: 28px;
}

.membership__top .top__text {
  margin: 0 auto 32px auto;
  max-width: 500px;
}

.membership__top .top__text p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #2c2c2c;
}

.membership__benefits {
  padding: 50px 0 32px 0;
}

.membership__benefits .section__title {
  text-align: center;
}

.membership__benefits .benefits__list {
  padding: 24px 15px;
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
}

.membership__benefits .benefits__list .card:after {
  content: "";
  display: block;
  margin: 24px auto;
  width: 150px;
  height: 1px;
  background-color: #eee;
}

.membership__benefits .benefits__list .benefit__number {
  margin: 0 auto 16px auto;
  padding: 5px 0 4px 0;
  width: 32px;
  height: 32px;
  font-size: 18.3px;
  font-weight: 700;
  text-align: center;
  color: #2083ce;
  border-radius: 7.6px;
  background-color: #d1e7f9;
}

.membership__benefits .benefits__list .benefit__text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: #484848;
}

.membership__bursaries {
  padding: 32px 0;
}

.membership__bursaries .bursaries__text {
  margin-bottom: 32px;
}

.membership__bursaries .bursaries__text p {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #2c2c2c;
}

.membership__bursaries .bursaries__text p:last-child {
  margin-bottom: 0;
}

.membership__bursaries .bursaries__image .image {
  max-width: 100%;
}

.membership__bursaries .bursaries__image .image--desktop {
  display: none;
}

.membership__members {
  padding: 32px 0 64px 0;
}

.membership__members .members__list .list__item {
  margin-bottom: 10px;
}

.membership__members .members__list .card {
  margin: 0 auto;
  width: 211px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0);
}

.membership__members .members__list .card .member__post {
  margin-bottom: 1px;
  font-size: 12px;
  line-height: 1.5;
  color: #484848;
}

.membership__members .members__list .card .member__name {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.38;
  color: #000;
}

/** Membership **/

/* Speakers */
.speakers__list .list__item {
  margin: 0 30px 10px 0;
  display: inline-block;
}

.speakers__list .card {
  padding: 10px 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.speakers__list .speaker__location {
  margin-bottom: 1px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #272727;
}

.speakers__list .speaker__name {
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #000;
}

.speakers__list .speaker__post {
  font-size: 10px;
  font-weight: 700;
  line-height: 2.2;
  color: #3190d7;
  text-transform: uppercase;
}

/** Speakers **/
/* Presentations */
.presentations__content {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1440px;
}

.disclaimer {
  max-width: max-content;
  margin: auto;
  padding: 16px 32px;
  border-radius: 10px;
  box-shadow: 0 0 34px 0 #efefef;
  background-color: #fff;
  margin-bottom: 32px;
}

.disclaimer p {
  font-size: 12px;
  color: #000;
  font-weight: bold;
  opacity: 0.7;
  margin-bottom: 7px;
  text-align: center;
}

.presentations--page {
  padding-top: 42px;
}

.presentations--page .page__title {
  text-transform: uppercase;
}

.presentations--page .search {
  margin: 0 auto 28px;
  max-width: 620px;
}

.presentations__list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 8px -16px;
}

.presentations__list .list__item {
  margin: 0 16px 20px 16px;
}

.presentations__list .list__item:last-child {
  margin-bottom: 0;
}

.presentations__list .card {
  max-width: 350px;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 0 34px 0 #efefef;
  background-color: #fff;
}

.presentations__list .presentation__location {
  margin-bottom: 1px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #272727;
}

.presentations__list .presentation__speaker {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #000;
}

.presentations__list .presentation__title {
  margin-bottom: 16px;
  font-weight: 500;
  line-height: 1.56;
  color: #000;
}

.presentations__list .presentation__description {
  margin-bottom: 24px;
}

.presentations__list .presentation__description p {
  font-size: 14px;
  line-height: 1.57;
  color: #161616;
}

.presentations__list .speaker__post {
  margin-bottom: 9px;
  font-size: 10px;
  font-weight: 700;
  line-height: 2.2;
  color: #3190d7;
}

/** Presentations **/
/* Websites */
.websites__content {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1440px;
}

.websites--page {
  padding-top: 42px;
}

.websites--page .page__title {
  margin-bottom: 20px;
  text-transform: uppercase;
}

.websites--page .page__subtitle {
  margin-bottom: 0;
  font-size: 16px;
  text-transform: uppercase;
}

.websites__list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 8px -16px 64px -16px;
}

.websites__list .list__item {
  margin: 0 16px 20px 16px;
  text-decoration: none;
  color: inherit;
}

.websites__list .card {
  padding: 24px 28px;
  width: 260px;
  height: 100%;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 34px 0 #efefef;
}

.websites__list .website__logo {
  margin-bottom: 10px;
  height: 32px;
}

.websites__list .website__logo .image {
  max-height: 100%;
}

.websites__list .website__title {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.44;
  color: #000;
}

.websites__list .website__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #646464;
}

/* Websites */
/* Profile */
.profile__content {
  margin: 0 auto;
  padding: 64px 16px;
  max-width: 430px;
}

.profile__form .form__label--radio {
  display: inline-block;
  width: 48%;
}

.profile__form .form__label--checkbox {
  padding-left: 40px;
}

.profile__form .form__label--checkbox:before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
}

/** Profile **/
/* Subscription */
.subscription__content {
  margin: 0 auto;
  padding: 42px 16px;
  width: 90%;
  max-width: 800px;
}

.subscription--page .subscription__block {
  margin-bottom: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.17);
}

.subscription--page .subscription__block:last-child {
  border-bottom: 0 none;
}

.subscription--page .subscription__form.show-update .form--payment {
  display: none;
}

.subscription--page .subscription__form.show-update .form--update {
  display: block;
}

.subscription--page .block__title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  color: #2083ce;
}

.subscription--page .block__text p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
}

.latest-payments {
  margin-bottom: 15px;
}

.subscription--page .block__text p a {
  color: #3aaaff;
}

.subscription--page .block__text p .link--invoces {
  font-size: 12px;
  font-weight: 700;
  color: #3aaaff;
  text-decoration: none;
}

.subscription--page .block__text p:last-child {
  margin-bottom: 0;
}

.subscription--page .form--payment .btns-update {
  display: block;
}

.subscription--page .form--update {
  display: none;
}

.subscription--page .form--update .form__row--subcolumns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -8px;
}

.subscription--page .form--update .form__row--subcolumns .form__row {
  flex: 0 0 50%;
  padding: 0 8px;
}

.subscription--page .form--update .form__row--btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription--page .form--update .form__row--btns .btns {
  padding: 11px 24px;
  width: 136px;
}

.subscription--page .form--update .form__input--card {
  padding-right: 40px;
}

.subscription--page .form--update .form__input--exp,
.subscription--page .form--update .form__input--cvc {
  text-align: center;
}

.subscription__empty {
  margin: 0 auto;
  padding-top: 42px;
  max-width: 525px;
  text-align: center;
}

.subscription__empty .empty__text {
  margin: 0 auto 32px;
  max-width: 500px;
}

/** Subscription **/
/** Content **/
/* Footer */
.footer {
  padding: 16px 0;
  background-color: #fff;
  border: solid 1px rgba(213, 213, 213, 0.25);
}

.footer__content {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1440px;
}

.footer__contacts {
  margin-bottom: 16px;
}

.footer__contacts .contacts__label {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #53a7e5;
}

.footer__contacts .contacts__name {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #000;
}

.footer__contacts .contacts__email {
  font-size: 14px;
  color: #000;
}

.footer__menu ul li {
  margin-bottom: 4px;
}

.footer__menu ul li a {
  display: block;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.footer__copyrights {
  font-size: 14px;
  color: #000;
}

/** Footer **/
/* Popup */
.popup {
  display: none;
}

.popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.63);
  z-index: 100;
}

.popup__block {
  display: none;
  position: relative;
  margin: 0 16px;
  padding: 52px 42px 24px 42px;
  width: 100%;
  max-width: 530px;
  max-height: 80vh;
  border-radius: 10px;
  box-shadow: 0 0 34px 0 #efefef;
  background-color: #fff;
  overflow-y: auto;
}

.popup__block .block__top .btns-close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url("./img/icons/i-close.svg");
}

.popup__block .block__title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #54a7e5;
}

.popup__block .block__content {
  margin-bottom: 32px;
}

.popup__block .block__content p {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #000;
}

.popup__block .block__btns {
  text-align: center;
}

.popup__block .block__bottom {
  padding-top: 12px;
  text-align: center;
}

.popup__block .block__bottom .link {
  font-size: 14px;
  color: #0092ff;
  text-decoration: none;
}

.popup.show .popup__overlay {
  display: flex;
}

.popup.webinar .popup--webinar,
.popup.presentations .popup--presentations {
  display: block;
}

.popup.show {
  display: block;
}

/** Popup **/
/* Icons */
.icons {
  display: inline-block;
  font-size: 0;
  line-height: 1;
  vertical-align: middle;
}

.icons.bg::after {
  content: "";
  display: inline-block;
  font-size: 0;
  line-height: 1;
  background-position: 50%;
  background-repeat: no-repeat;
}

.icons.bg.i-menu::after {
  /* background-image: url("./img/icons/i-menu.svg"); */
  background-size: 100%;
}

.icons.bg.i-logout::after {
  background-image: url("./img/icons/i-logout.svg");
  background-size: 100%;
}

.icons.bg.i-info::after {
  background-image: url("./img/icons/i-info.svg");
  background-size: 100%;
}

.icons.bg.i24x24::after {
  width: 24px;
  height: 24px;
}

.icons.svg.i16x16 {
  width: 16px;
  height: 16px;
}

.icons.svg.i18x18 {
  width: 18px;
  height: 18px;
}

.icons.svg.i24x24 {
  width: 24px;
  height: 24px;
}

/** Icons **/
/* Buttons */
.btns {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
}

.btns-trprnt,
.btns-opacity,
.btns-blue {
  padding: 11px 32px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #0092ff;
  border-radius: 10px;
}

.btns-trprnt:hover {
  background-color: rgba(179, 217, 245, 0.6);
}

.btns-opacity {
  background-color: rgba(179, 217, 245, 0.6);
}

.btns-opacity:hover {
  color: #fff;
  background-color: #2083ce;
}

.btns-blue {
  color: #fff;
  background-color: #2083ce;
}

.btns-blue:hover {
  color: #0092ff;
  background-color: rgba(179, 217, 245, 0.6);
}

.btns-menu,
.btns-auth {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 7.6px;
  background-color: rgba(179, 217, 245, 0.6);
}

.btns-menu span,
.btns-auth span {
  display: block;
  margin: 2px auto;
  width: 16px;
  height: 2px;
  background-color: #159afe;
  border-radius: 2px;
}

.btns-close {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 7.6px;
  background-color: rgba(179, 217, 245, 0.6);
}

.btns-close span {
  display: block;
  margin: -2px 0px 0px 8px;
  width: 16px;
  height: 2px;
  background-color: #159afe;
  border-radius: 2px;
  transform: rotate(45deg);
}

.btns-close span:last-child {
  transform: rotate(-45deg);
}

/** Buttons **/
/* Common */
.hidden {
  display: none;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  visibility: hidden;
}

/** Common **/
/* Media */
@media only screen and (min-width: 768px) {
  /* Content */
  /* Membership */
  .membership__benefits .benefits__list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .membership__benefits .benefits__list .list__item {
    flex: 0 0 50%;
    padding: 24px;
  }

  .membership__benefits .benefits__list .list__item:nth-child(5) .card:after,
  .membership__benefits .benefits__list .list__item:nth-child(6) .card:after {
    display: none;
  }

  .membership__benefits .benefits__list .list__item .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: 100%;
  }

  .membership__benefits .benefits__list .list__item .benefit__text {
    flex: 1 0 auto;
  }

  .membership__bursaries .bursaries__text {
    float: left;
    max-width: 400px;
  }

  .membership__bursaries .bursaries__image {
    text-align: right;
  }

  .membership__members .members__list {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-wrap: wrap;
    max-width: 500px;
  }

  .membership__members .members__list .list__item {
    margin: 0 26px 16px 0;
  }

  /** Membership **/

  /* Presentations */
  .presentations__list .list__item {
    margin-bottom: 32px;
  }

  /** Presentations **/
  /* Subscription */

  .subscription--page .form--payment .btns-update {
    display: inline-block;
  }

  .subscription--page .form--payment .form__row--columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subscription--page
    .form--payment
    .form__row--columns
    .form__row:first-child {
    flex: 1;
    margin-right: 32px;
  }

  .subscription--page .form--update .form__row--columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subscription--page .form--update .form__row--columns .form__row--card,
  .subscription--page .form--update .form__row--columns .form__row--subcolumns {
    margin-right: 16px;
  }

  .subscription--page .form--update .form__row--subcolumns .form__input {
    width: auto;
  }

  .subscription--page .form--update .form__row--btns .btns {
    width: auto;
    margin-left: 16px;
  }

  .subscription__empty {
    padding-top: 120px;
  }

  /** Subscription **/
  /** Content **/
  /* Footer */
  .footer__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .footer__contacts {
    margin-bottom: 0;
  }

  /** Footer **/
}

@media only screen and (min-width: 1024px) {
  /* Content */
  .page__wrapper {
    background: url("./img/page_bg.svg") no-repeat 50% 0;
    background-size: cover;
  }

  .page__title {
    font-size: 32px;
    line-height: 1.31;
  }

  .page__subtitle {
    font-size: 22px;
  }

  /* Signinup */
  .signinup {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }

  .signinup .page__title {
    margin-bottom: 42px;
    padding-left: 65px;
    text-align: left;
  }

  .signinup__bg {
    display: flex;
  }

  .signinup__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 64px 45px;
  }

  .signinup__form {
    margin: 0;
  }

  .signinup__form .form__row--columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-star;
    margin-right: -15px;
    margin-left: -15px;
  }

  .signinup__form .form__row--columns .column--2 {
    flex: 0 0 50%;
    margin-bottom: 0;
    padding: 0px 15px;
  }

  /** Signinup **/
  /* Membership */
  .membership__content {
    padding-right: 50px;
    padding-left: 50px;
  }

  .membership__top {
    padding-top: 100px;
    padding-bottom: 50px;
    background-image: url("./img/membeship_top_bg.svg");
    background-position: 50%;
    background-size: cover;
  }

  .membership__top .page__title {
    margin-bottom: 24px;
    font-size: 52px;
  }

  .membership__top .top__text {
    margin-bottom: 42px;
  }

  .membership__top .top__text p {
    font-size: 16px;
  }

  .membership__benefits {
    padding-top: 70px;
    padding-bottom: 80px;
  }

  .membership__benefits .section__title {
    margin-bottom: 42px;
  }

  .membership__benefits .benefits__list {
    padding: 42px 54px 18px 54px;
  }

  .membership__benefits .benefits__list .list__item {
    flex: 0 0 33%;
  }

  .membership__benefits .benefits__list .list__item:nth-child(4) .card:after {
    display: none;
  }

  .membership__benefits .benefits__list .list__item:nth-child(2),
  .membership__benefits .benefits__list .list__item:nth-child(5) {
    position: relative;
  }

  .membership__benefits .benefits__list .list__item:nth-child(2):before,
  .membership__benefits .benefits__list .list__item:nth-child(2):after,
  .membership__benefits .benefits__list .list__item:nth-child(5):before,
  .membership__benefits .benefits__list .list__item:nth-child(5):after {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 150px;
    background-color: #eee;
  }

  .membership__benefits .benefits__list .list__item:nth-child(2):before,
  .membership__benefits .benefits__list .list__item:nth-child(5):before {
    left: 0;
  }

  .membership__benefits .benefits__list .list__item:nth-child(2):after,
  .membership__benefits .benefits__list .list__item:nth-child(5):after {
    right: 0;
  }

  .membership__benefits .benefits__list .list__item:nth-child(5):after {
    width: 0;
  }

  .membership__benefits .benefits__list .card:after {
    margin-top: 40px;
    margin-bottom: 0;
  }

  .membership__bursaries {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .membership__bursaries .section__title {
    margin-bottom: 42px;
  }

  .membership__bursaries .bursaries__text {
    float: none;
    max-width: 500px;
  }

  .membership__bursaries .bursaries__text p {
    font-size: 16px;
  }

  .membership__bursaries .bursaries__image {
    margin-top: -150px;
    margin-left: 400px;
  }

  .membership__bursaries .bursaries__image .image--mobile {
    display: none;
  }

  .membership__bursaries .bursaries__image .image--desktop {
    display: inline-block;
  }

  .membership__members .section__title {
    margin-bottom: 42px;
  }

  .membership__members .members__list {
    max-width: 650px;
  }

  .membership__members .members__list .card {
    padding: 16px 24px;
    width: 260px;
  }

  .membership__members .members__list .card .member__post {
    font-size: 14px;
  }

  .membership__members .members__list .card .member__name {
    font-size: 18px;
  }

  /** Membership **/

  /* Presentations */
  .presentations__content {
    padding-right: 50px;
    padding-left: 50px;
  }

  .presentations__list .presentation__title {
    font-size: 18px;
  }

  /** Presentations **/
  /* Websites */
  .websites__content {
    padding-right: 50px;
    padding-left: 50px;
  }

  .websites--page .page__title {
    margin-bottom: 32px;
    font-size: 24px;
  }

  /** Websites **/
  /* Profile */
  .profile__form {
    margin: 0;
  }

  .profile__form .form__row--columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-star;
    margin-right: -15px;
    margin-left: -15px;
  }

  .profile__form .form__row--columns .column--2 {
    flex: 0 0 50%;
    margin-bottom: 0;
    padding: 0px 15px;
  }

  /** Profile **/
  /** Content **/
  /* Footer */
  .footer__content {
    padding: 0px 50px;
  }

  /** Footer **/
  /* Popup */
  .popup__block .block__title {
    font-size: 24px;
  }

  /** Popup **/
}

@media only screen and (min-width: 1440px) {
  /* Header */
  .header__content {
    padding: 0px 160px;
  }

  /** Header **/
  /* Content */
  /* Signinup */
  .signinup__content {
    padding-left: 145px;
  }

  /** Signinup **/
  /* Welcome */
  .welcome__content {
    padding: 120px 160px;
  }

  .welcome__title {
    font-size: 52px;
    line-height: 1.23;
  }

  /** Welcome **/
  /* Membership */
  .membership__content {
    padding-right: 160px;
    padding-left: 160px;
  }

  /** Membership **/

  /* Presentations */
  .presentations__content {
    padding-right: 160px;
    padding-left: 160px;
  }

  /** Presentations **/
  /* Websites */
  .websites__content {
    padding-right: 160px;
    padding-left: 160px;
  }

  /** Websites **/
  /** Content **/
  /* Footer */
  .footer__content {
    padding: 0px 160px;
  }

  /** Footer **/
}

@media (max-width: 850px) {
  .subscription__content {
    width: 95%;
  }

  .sub--mobile {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .sub--mobile .form__row {
    width: 100% !important;
  }

  .sub--mobile .form__row .btns {
    width: 100%;
    margin-bottom: 12px;
  }
}

/** Media **/

/*# sourceMappingURL=styles.css.map */
