.terms {
  width: 70%;
  margin: auto;
  list-style-position: inside;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 60px;
}

.terms > ol > li:first-of-type {
  margin-top: 32px;
}

.terms > ol > li {
  margin-bottom: 32px;
}

.terms > ol ol {
  margin-left: 16px;
}

.terms > ol > li * {
  margin-bottom: 4px;
}

.terms ul {
  margin-left: 16px;
}

@media (max-width: 1100px) {
  .terms {
    width: 85%;
  }
}